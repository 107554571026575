@font-face {
  font-family: 'ssp-icons';
  src:  url('/public/assets/fonts/ssp-icons.eot?x70xsx');
  src:  url('/public/assets/fonts/ssp-icons.eot?x70xsx#iefix') format('embedded-opentype'),
    url('/public/assets/fonts/ssp-icons.ttf?x70xsx') format('truetype'),
    url('/public/assets/fonts/ssp-icons.woff?x70xsx') format('woff'),
    url('/public/assets/fonts/ssp-icons.svg?x70xsx#ssp-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ssp-"], [class*=" ssp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ssp-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.2;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ssp-cross:before {
  content: "\e939";
}
.ssp-document:before {
  content: "\e937";
}
.ssp-person-add:before {
  content: "\e938";
}
.ssp-accesibility:before {
  content: "\e933";
}
.ssp-calendar-clean:before {
  content: "\e934";
}
.ssp-checkmark-circle:before {
  content: "\e935";
}
.ssp-checkmark:before {
  content: "\e936";
}
.ssp-bell:before {
  content: "\e93c";
}
.ssp-person:before {
  content: "\e941";
}
.ssp-print_outline:before {
  content: "\e942";
}
.ssp-receipt:before {
  content: "\e943";
}
.ssp-send_outline:before {
  content: "\e944";
}
.ssp-storefront:before {
  content: "\e945";
}
.ssp-delete:before {
  content: "\e905";
}
.ssp-video:before {
  content: "\e900";
}
.ssp-vertical:before {
  content: "\e901";
}
.ssp-calendar:before {
  content: "\e903";
}
.ssp-time-fill:before {
  content: "\e904";
}
.ssp-time:before {
  content: "\e902";
}
.ssp-swap:before {
  content: "\e906";
}
.ssp-star-fill:before {
  content: "\e907";
}
.ssp-star:before {
  content: "\e908";
}
.ssp-settings:before {
  content: "\e909";
}
.ssp-send:before {
  content: "\e90a";
}
.ssp-search:before {
  content: "\e90b";
}
.ssp-report:before {
  content: "\e90c";
}
.ssp-reply:before {
  content: "\e90d";
}
.ssp-print:before {
  content: "\e90e";
}
.ssp-people:before {
  content: "\e90f";
}
.ssp-external:before {
  content: "\e910";
}
.ssp-notifications:before {
  content: "\e911";
}
.ssp-menu:before {
  content: "\e912";
}
.ssp-triangle-up:before {
  content: "\e913";
}
.ssp-triangle-right:before {
  content: "\e914";
}
.ssp-triangle-down:before {
  content: "\e915";
}
.ssp-triangle-left:before {
  content: "\e916";
}
.ssp-medkit:before {
  content: "\e917";
}
.ssp-mail:before {
  content: "\e918";
}
.ssp-log-out:before {
  content: "\e919";
}
.ssp-label:before {
  content: "\e91a";
}
.ssp-label-2:before {
  content: "\e91b";
}
.ssp-inbox:before {
  content: "\e91c";
}
.ssp-help:before {
  content: "\e91d";
}
.ssp-file:before {
  content: "\e91e";
}
.ssp-pharmacy:before {
  content: "\e91f";
}
.ssp-eye-off:before {
  content: "\e920";
}
.ssp-drive:before {
  content: "\e921";
}
.ssp-delete-fill:before {
  content: "\e922";
}
.ssp-close:before {
  content: "\e923";
}
.ssp-chevron-up:before {
  content: "\e924";
}
.ssp-chevron-right:before {
  content: "\e925";
}
.ssp-chevron-left:before {
  content: "\e926";
}
.ssp-chevron-down:before {
  content: "\e927";
}
.ssp-calendar-2:before {
  content: "\e928";
}
.ssp-brush:before {
  content: "\e929";
}
.ssp-arrow-up:before {
  content: "\e92a";
}
.ssp-arrow-right:before {
  content: "\e92b";
}
.ssp-arrow-down:before {
  content: "\e92c";
}
.ssp-arrow-left:before {
  content: "\e92d";
}
.ssp-archive:before {
  content: "\e92e";
}
.ssp-grid:before {
  content: "\e92f";
}
.ssp-add:before {
  content: "\e930";
}
.ssp-add-task:before {
  content: "\e931";
}
.ssp-account-fill:before {
  content: "\e932";
}
