/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
/** Ionic CSS Variables **/
:root {
  --ion-text-color: var(--dark);
  --ion-font-family:"Volta", sans-serif;
  --header-height:90px;
  --trans:all .2s ease-in;
  --m-shadow:0 2px 10px rgba(8,84,117,.1);
  --s-shadow:0 1px 10px rgba(8,84,117,.03);
  --prim: #307AEE;
  --prim120: #295daf;
  --prim70: #6EA2F3;
  --prim50: #97BCF6;
  --prim30: #C1D7FA;
  --prim10: #EAF2FD;
  --prim5: #F6F9FE;
  --sec: #0460A9;
  --sec70: #4F90C3;
  --sec50: #81AFD4;
  --sec30: #B4CFE5;
  --sec20: #dbe6f0;
  --sec10: #E6EFF6;
  --sec5: #f0f6fe;
  --blue1:#06477B;
  --blue2:#71B6EB;
  --blue3:#65BEEC;
  --dark: #2B3034;
  --dark70: #6D6D6D;
  --dark50: #959799;
  --dark30: #BFC1C2;
  --dark10: #EDECEC;
  --dark5: #f8f7f7;
  --red:#FF5656;
  --red120:#e23939;
  --red70:#FC8888;
  --granate:#8A0F11;
  --green:#7AB92C;
  --green70:#70D068;
  --green20:#F2F8EA;
  --orange:#FFA756;
  --yellow:#FFE456;
  --yellow50:#FFFEE8;
  --pink:#FF70AE;
  --dark-pink:#C24785;
  --purple:#A36BFF;
  --blue:#65BEEC;
  /* FONT SIZES */
  --xxs: 10px;
  --xs: 12px;
  --s: 14px;
  --m: 16px;
  --ml: 18px;
  --l: 20px;
  --xml: 24px;
  --xl: 28px;
  --xxml: 32px;
  --xxl: 42px;
  --xxxl: 68px;
}

.form-group {
  margin-bottom: 2.5rem;
  position: relative;
}
.form-group.select:before {
  font-family: "ssp-icons";
  content: "\e927";
  position: absolute;
  font-size: 20px;
  right: 10px;
  bottom: 3px;
  background: transparent;
  border: none;
  color: var(--dark50);
  pointer-events: none;
}
.form-group.select.with-icon select {
  padding-left: 1.75rem;
}
.form-group.select.with-icon i {
  position: absolute;
  left: 0;
  bottom: 7px;
  font-size: 20px;
}
.form-group .form-label {
  font-size: var(--s);
  color: var(--dark70);
  margin-bottom: 0.25rem;
  font-weight: 200;
  transition: var(--trans);
  letter-spacing: 0.25px;
}
.form-group .form-control {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--dark30);
  color: var(--dark);
  font-weight: 600;
  box-shadow: none !important;
  padding-left: 0;
  transition: var(--trans);
}
.form-group .form-control[readonly] {
  cursor: default;
}
.form-group .form-control[readonly]:focus {
  border-color: var(--dark30);
}
.form-group .form-control:focus {
  border-color: var(--sec);
  box-shadow: none;
}
.form-group .form-control::-moz-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-control::placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-control:-ms-input-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-control::-ms-input-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-control[disabled] {
  opacity: 0.3;
}
.form-group textarea.form-control {
  font-size: var(--s);
  font-weight: 500;
  border: 1px solid var(--dark30);
  padding: 0.75rem;
  border-radius: 10px;
}
.form-group .textarea-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--prim10);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0 0 10px 10px;
  align-items: center;
}
.form-group .form-select {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--dark30);
  color: var(--dark);
  font-weight: 500;
  box-shadow: none !important;
  padding-left: 0;
  transition: var(--trans);
}
.form-group .form-select:focus {
  border-color: var(--sec);
}
.form-group .form-select::-moz-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-select::placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-select:-ms-input-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group .form-select::-ms-input-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.form-group.error .form-label {
  color: var(--red);
}
.form-group.error .form-control {
  border-color: var(--red);
}
.form-group:focus-within .form-label {
  color: var(--prim);
}
.form-group .show-password {
  position: absolute;
  right: 0;
  bottom: 5px;
  background: transparent;
  border: none;
  color: var(--prim);
}
.form-group.readonly label {
  font-size: var(--s);
  color: var(--dark30);
  display: block;
  margin-bottom: 0.35rem;
}
.bg-sec .form-group:focus-within .form-label {
  color: white !important;
}
.bg-sec .form-group .form-control {
  color: white;
  border-color: var(--dark30);
}
.bg-sec .form-group .form-control:focus {
  border-color: white;
}
.bg-sec .form-group .form-control::-moz-placeholder {
  color: var(--prim30);
  font-weight: 100;
}
.bg-sec .form-group .form-control::placeholder {
  color: var(--prim30);
  font-weight: 100;
}
.bg-sec .form-group .form-control:-ms-input-placeholder {
  color: var(--prim30);
  font-weight: 100;
}
.bg-sec .form-group .form-control::-ms-input-placeholder {
  color: var(--prim30);
  font-weight: 100;
}

.error-msg {
  margin: 0;
  padding: 0.25rem 0;
  font-size: var(--xs);
  color: var(--red);
}

.form-check {
  margin-bottom: 0;
}

.form-check-input[type=radio]:focus, .form-check-input[type=radio]:active {
  box-shadow: none;
  outline: none;
  background-color: white;
  border-color: var(--sec);
  filter: brightness(100%);
}
.form-check-input[type=radio]:checked {
  box-shadow: none;
  outline: none;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23307AEE%27/%3e%3c/svg%3e");
}
.form-check-input[type=radio]:checked:disabled {
  opacity: 1;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23bbbbbb%27/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  opacity: 1;
}
.form-check-input:disabled ~ .form-check-label {
  opacity: 1;
}
.form-check-input[type=checkbox] {
  box-shadow: none;
  outline: none;
  margin-left: -1em;
}
.form-check-input[type=checkbox]:checked {
  accent-color: var(--prim);
  background-color: var(--prim);
  border-color: var(--prim);
}
.form-check-input[type=checkbox]:disabled {
  opacity: 1;
}
.form-check-input[type=checkbox]:focus, .form-check-input[type=checkbox]:active {
  box-shadow: none;
  outline: none;
  border-color: var(--dark50);
}
.form-check-input[type=checkbox] + .form-check-label {
  font-size: var(--s);
  padding-left: 0.5rem;
}

.multiselect-container .search-wrapper {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--dark30);
  color: var(--dark);
  font-weight: 500;
  transition: var(--trans);
  position: relative;
}
.multiselect-container .search-wrapper:before {
  font-family: "ssp-icons";
  content: "\e917";
  position: absolute;
  font-size: 20px;
  left: 0px;
  bottom: 3px;
  background: transparent;
  border: none;
  color: var(--dark50);
  pointer-events: none;
}
.multiselect-container .searchBox {
  font-size: var(--s);
  font-weight: 100;
  width: 100%;
  padding-left: 1.5rem;
}
.multiselect-container .chip {
  background: transparent;
  font-size: var(--s);
  color: var(--dark70);
  border-radius: 0;
  padding-left: 0;
  margin-right: 0;
}
.multiselect-container .chip:not(:last-of-type):after {
  content: ",";
}
.multiselect-container .chip .closeIcon {
  display: none;
}
.multiselect-container .optionListContainer ul {
  border: none;
  box-shadow: var(--m-shadow);
}
.multiselect-container .optionListContainer ul .option {
  font-size: var(--s);
}
.multiselect-container .optionListContainer ul .option.highlightOption,
.multiselect-container .optionListContainer ul .option:hover {
  background: var(--prim5);
  color: var(--dark);
}

.searchbar-wrapper form {
  display: flex;
  align-items: center;
}
.searchbar-wrapper .form-group {
  margin-bottom: 2rem;
}
.searchbar-wrapper .form-group.searchbar {
  width: 100%;
  border: 1px solid var(--dark30);
  border-radius: 4px;
  position: relative;
}
.searchbar-wrapper .form-group.searchbar i {
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 14px);
  font-size: 16px;
}
.searchbar-wrapper .form-group.searchbar input {
  padding-right: 2rem;
  padding-left: 1rem;
  border: none;
}
.searchbar-wrapper .form-group.select {
  width: auto;
  border-radius: 20px;
  border: 1px solid var(--dark70);
  padding-left: 1rem;
  min-width: 200px;
  border: 1px solid var(--dark30);
  margin-left: 1rem;
}
.searchbar-wrapper .form-group.select select {
  border: none;
}

.medition-input {
  border: 1px solid var(--sec20);
  border-radius: 12px;
  padding: 0.5rem;
  display: inline-flex;
  align-items: stretch;
  margin-right: 2rem;
  margin-top: 1rem;
}
.medition-input .separator {
  display: block;
  width: 1px;
  height: auto;
  background: var(--sec20);
  margin: 0 0.75rem;
}
.medition-input .form-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
}
.medition-input .form-group .form-label {
  /*  white-space: nowrap; */
  color: var(--prim70);
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}
.medition-input .form-group .form-control {
  background: white;
  border: 1px solid var(--prim30);
  border-radius: 10px;
  width: 75px;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
}
.medition-input .form-group .form-control[readonly]:focus, .medition-input .form-group .form-control[readonly]:active {
  border: 1px solid var(--prim30);
}
.medition-input .form-group .form-control:focus, .medition-input .form-group .form-control:active {
  border: 1px solid var(--prim70);
}
.medition-input .form-group .form-select {
  background-color: white;
  border: 1px solid var(--prim30);
  border-radius: 10px;
  min-width: 75px;
  width: auto;
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  margin: 0 0.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a4055' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.35rem center;
  background-size: 16px 12px;
}
.medition-input .form-group .form-select:focus, .medition-input .form-group .form-select:active {
  border: 1px solid var(--prim70);
}
.medition-input .form-group .form-select::-webkit-input-placeholder {
  color: var(--dark30);
  font-weight: 100;
}
.medition-input .form-group .unit {
  font-size: var(--s);
}
.medition-input.green .form-label:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--green70);
  display: inline-block;
  margin-right: 0.25rem;
}
.medition-input.orange .form-label:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--orange);
  display: inline-block;
  margin-right: 0.25rem;
}
.medition-input.red .form-label:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--red);
  display: inline-block;
  margin-right: 0.25rem;
}

/* CLASES GENERALES */
.font-xxs {
  font-size: var(--xxs) !important;
}

.font-xs {
  font-size: var(--xs) !important;
}

.font-s {
  font-size: var(--s) !important;
}

.font-m {
  font-size: var(--m) !important;
}

.font-ml {
  font-size: var(--ml) !important;
}

.font-l {
  font-size: var(--l) !important;
}

.font-xml {
  font-size: var(--xml) !important;
}

.font-xl {
  font-size: var(--xl) !important;
}

.font-xxl {
  font-size: var(--xxl) !important;
}

.font-xxml {
  font-size: var(--xxml) !important;
}

.font-xxxl {
  font-size: var(--xxxl) !important;
}

/* Backgrounds */
.bg-white {
  background: white !important;
}

.bg-prim {
  background: var(--prim) !important;
}

.bg-prim70 {
  background: var(--prim70) !important;
}

.bg-prim50 {
  background: var(--prim50) !important;
}

.bg-prim30 {
  background: var(--prim30) !important;
}

.bg-prim20 {
  background: var(--prim20) !important;
}

.bg-prim10 {
  background: var(--prim10) !important;
}

.bg-prim5 {
  background: var(--prim5) !important;
}

.bg-sec {
  background: var(--sec) !important;
}

.bg-dark70 {
  background: var(--dark70) !important;
}

.bg-dark50 {
  background: var(--dark50) !important;
}

.bg-dark30 {
  background: var(--dark30) !important;
}

.bg-dark10 {
  background: var(--dark10) !important;
}

.bg-dark5 {
  background: var(--dark5) !important;
}

.bg-orange {
  background: var(--orange50) !important;
}

.bg-green20 {
  background: var(--green20) !important;
}

.bg-transparent {
  background: transparent !important;
}

/* Colores */
.color-white {
  color: white !important;
}

.color-dark {
  color: var(--dark) !important;
}

.color-dark70 {
  color: var(--dark70) !important;
}

.color-dark50 {
  color: var(--dark50) !important;
}

.color-dark30 {
  color: var(--dark30) !important;
}

.color-sec {
  color: var(--sec) !important;
}

.color-prim {
  color: var(--prim) !important;
}

.color-prim70 {
  color: var(--prim70) !important;
}

.color-prim50 {
  color: var(--prim50) !important;
}

.color-prim30 {
  color: var(--prim30) !important;
}

.color-prim10 {
  color: var(--prim10) !important;
}

.color-orange {
  color: var(--orange) !important;
}

.color-green {
  color: var(--green) !important;
}

.color-green70 {
  color: var(--green70) !important;
}

.color-red {
  color: var(--red) !important;
}

.color-darkblue {
  color: var(--blue1) !important;
}

/*   OTROS */
.no-shadow {
  box-shadow: none !important;
}

.m-shadow {
  box-shadow: var(--m-shadow) !important;
}

.fw-lighter {
  letter-spacing: 0.25px;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.radius-24 {
  border-radius: 24px !important;
}

.border-dark30 {
  border: 1px solid var(--dark30);
}

.rotate45 {
  transform: rotate(45deg);
}

.truncate-2rows {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cursor-pointer {
  cursor: pointer;
}

/* Distancias */
.p-2rem {
  padding: 2rem !important;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.modal .modal-dialog {
  max-height: calc(100vh - 4rem);
  margin: 2rem auto;
  overflow: hidden;
  border-radius: 20px;
}
.modal .modal-dialog .modal-content {
  border: none;
  border-radius: 20px;
}
.modal .modal-dialog .modal-content .modal-header {
  border: none;
  justify-content: flex-end;
  padding: 1.5rem 1.5rem 1rem;
}
.modal .modal-dialog .modal-content .modal-header .btn-close {
  width: 16px;
  height: 16px;
  padding: 0 !important;
  opacity: 1;
  margin: 0.25rem;
  box-shadow: none !important;
}
.modal .modal-dialog .modal-content .modal-header .btn-close:hover {
  opacity: 0.6;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 0rem 1.5rem;
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.modal .modal-dialog .modal-content .modal-footer {
  border: none;
  justify-content: center;
  padding: 1rem 1.5rem 1.5rem;
}
.modal.modal-meditions .modal-body h3.color-sec {
  display: none;
}
.modal.modal-meditions .modal-body .textarea-footer {
  display: none;
}
.modal.modal-meditions .modal-body .col-xl-7, .modal.modal-meditions .modal-body .col-xl-5 {
  width: 100%;
}

/* FIX PARA QUE UNA MODAL SE ABRA ENCIMA DE LA SIGUIENTE */
.modal.show + .modal-backdrop {
  z-index: 1056;
}
.modal.show + .modal-backdrop + .modal.show {
  z-index: 1057;
}

.btn {
  border-radius: 2px;
  border: 2px solid transparent;
  padding: 0.5rem 2.5rem;
  font-size: var(--m);
  font-weight: 600;
  opacity: 1;
  transition: var(--trans);
  display: inline-flex;
}
.btn.transparent {
  background: transparent;
  border-color: transparent;
  color: var(--sec);
  padding: 0;
  opacity: 1;
}
.btn.transparent:hover, .btn.transparent:active {
  background: transparent;
  border-color: transparent;
  opacity: 0.7;
}
.btn.white {
  background: white;
  border-color: white;
  color: var(--sec);
}
.btn.white:hover, .btn.white:active {
  opacity: 0.8;
}
.btn.prim {
  background: var(--prim);
  border-color: var(--prim);
  color: white;
}
.btn.prim:hover, .btn.prim:active {
  background: var(--prim120);
  border-color: var(--prim120);
}
.btn.prim.disabled, .btn.prim[disabled] {
  background: var(--dark50);
  border-color: var(--dark50);
}
.btn.prim30 {
  background: var(--prim30);
  border-color: var(--prim30);
  color: white;
}
.btn.prim30:hover, .btn.prim30:active {
  background: var(--prim50);
  border-color: var(--prim50);
}
.btn.prim30.disabled, .btn.prim30[disabled] {
  background: var(--dark50);
  border-color: var(--dark50);
}
.btn.prim10 {
  background: var(--prim10);
  border-color: var(--prim10);
  color: white;
}
.btn.prim10:hover, .btn.prim10:active {
  background: var(--prim30);
  border-color: var(--prim30);
}
.btn.prim10.disabled, .btn.prim10[disabled] {
  background: var(--dark30);
  border-color: var(--dark30);
}
.btn.prim.outline {
  background: transparent;
  border-color: var(--prim);
  color: var(--prim);
}
.btn.prim.outline:hover, .btn.prim.outline:active {
  color: var(--prim120);
  border-color: var(--prim120);
}
.btn.prim.outline.disabled, .btn.prim.outline[disabled] {
  opacity: 0.2;
}
.btn.red {
  background: var(--red);
  border-color: var(--red);
  color: white;
}
.btn.red:hover, .btn.red:active {
  background: var(--red120);
  border-color: var(--red120);
}
.btn.red.outline {
  background: transparent;
  border-color: var(--red);
  color: var(--red);
}
.btn.red.outline:hover, .btn.red.outline:active {
  color: var(--red120);
  border-color: var(--red120);
}
.btn.no-border {
  border: none;
}
.btn.alt-btn {
  border: none !important;
  background: var(--prim10);
  border-radius: 6px 24px 24px 6px;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  align-items: center;
  color: var(--prim);
}
.btn.alt-btn:hover, .btn.alt-btn:focus, .btn.alt-btn:active {
  background: var(--prim50);
  color: white;
}
.btn.alt-btn .icon-circle {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  background: var(--prim);
  color: white;
  font-size: 20px;
  margin-left: 0.75rem;
}
.btn.alt-btn span {
  white-space: nowrap;
}

.btn.pill,
.pill {
  border-radius: 20px;
  border: 1px solid var(--dark30);
  padding: 0.55rem 1rem 0.45rem;
  background: white;
  color: var(--dark);
  cursor: pointer;
  font-size: var(--s);
  margin: 0.25rem;
  line-height: 1;
  font-weight: 300;
}
.btn.pill .btn,
.pill .btn {
  height: auto;
  min-height: 0;
  line-height: 1;
  display: inline-block;
  border: none;
}
.btn.pill:hover,
.pill:hover {
  background: var(--prim5);
}
.btn.pill.active,
.pill.active {
  background: var(--prim50);
  border-color: var(--prim50);
  color: white;
}

.dropdown-menu {
  border: none;
  box-shadow: 2px 0 10px rgba(8, 84, 117, 0.1);
  padding: 0 1rem;
}
.dropdown-menu .dropdown-item {
  font-size: var(--m);
  font-weight: 300;
  padding: 1rem 0;
  color: var(--dark70);
}
.dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--prim);
}
.dropdown-menu .dropdown-item:not(:last-of-type) {
  border-bottom: 1px solid var(--dark10);
}

.profile-select .btn.dropdown-toggle {
  background: transparent;
  border: 1px solid white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}
.profile-select .btn.dropdown-toggle .avatar {
  background: var(--prim10);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--prim);
}
.profile-select .btn.dropdown-toggle .text {
  padding-left: 1rem;
  text-align: left;
  color: var(--prim);
}
.profile-select .btn.dropdown-toggle .text p {
  white-space: nowrap;
}

.icon-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex: 0 0 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-circle.big {
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
}

.activity-btn {
  background: var(--prim5);
  border: 1px solid var(--prim30);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  color: var(--prim);
}
.activity-btn:hover, .activity-btn:focus, .activity-btn:active {
  background: var(--prim10);
  border: 1px solid var(--prim50);
}
.activity-btn .icon-circle {
  position: relative;
}
.activity-btn .icon-circle > span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--prim);
  color: white;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  flex: 0 0 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.day-btn {
  width: 65px;
  padding: 0;
  color: var(--dark30) !important;
  font-weight: 700;
  background: transparent !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: var(--xl);
  border: none !important;
  position: relative;
}
@media (max-width: 1500px) {
  .day-btn {
    width: 60px;
  }
}
@media (max-width: 1400px) {
  .day-btn {
    width: 52px;
  }
}
.day-btn:hover, .day-btn:focus, .day-btn:active {
  background: transparent;
  opacity: 0.8;
}
.day-btn.current {
  color: var(--blue1) !important;
}
.day-btn.current-selected {
  color: var(--blue3) !important;
}
.day-btn .has-appointment {
  background: var(--prim10);
  /* Puntito azul */
  /* &:before{
      content:"";
      width:6px;
      height:6px;
      background:var(--prim);
      border-radius:50%;
      position:absolute;
      top:100%;
      left:0;
      right:0;
      margin:.25rem auto
  } */
}
.day-btn label {
  font-size: var(--xs);
  font-weight: 300;
}

.login-page {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin-top: -5rem;
}
.login-page.register {
  justify-content: flex-start;
}
.login-page.register:before {
  content: "";
  background-image: url("/public/assets/img/crosses2.svg");
  width: 490px;
  height: 520px;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  z-index: 1;
  top: 0;
  right: -4rem;
  z-index: -1;
}
.login-page.register form .col-lg-6 {
  padding-right: 8rem;
}
.login-page.register form .col-lg-6 .form-group {
  margin-bottom: 2.5rem;
}
.login-page:before {
  content: "";
  background-image: url("/public/assets/img/login-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 45%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -1;
  margin: 0;
}
.login-page .logo-wrapper {
  /* height:100px; */
  position: relative;
}
.login-page .logo-wrapper .logo {
  position: fixed;
  top: 3rem;
  left: 3rem;
}
.login-page form {
  width: 100%;
}
.login-page .card {
  padding: 3rem;
  margin-bottom: -150px;
}
@media (max-width: 992px) {
  .login-page .card {
    padding: 1rem;
  }
}
.login-page .card .form-group {
  margin-bottom: 2rem;
}
.login-page .card .form-group .form-label {
  color: var(--prim10);
}
.login-page .container-fluid img.login-bg {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.table-wrapper {
  overflow: hidden;
  position: relative;
  font-size: var(--s);
  border-radius: 8px;
  /* box-shadow: var(--m-shadow); */
}
.table-wrapper.empty {
  opacity: 0.3;
}
.table-wrapper.empty .table {
  --bs-table-striped-bg: var(--prim5);
  --bs-table-accent-bg: var(--prim5);
}
.table-wrapper .table {
  --bs-table-striped-bg: white;
  --bs-table-accent-bg: var(--prim5);
  margin: 0;
}
.table-wrapper .table thead tr th {
  background: var(--prim70);
  color: white;
  box-shadow: none;
  padding: 1rem;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
}
.table-wrapper .table thead tr th .sort-btn {
  padding: 0;
  font-size: 14px;
  color: white;
  background: transparent;
  border: none;
}
.table-wrapper .table tbody tr td {
  vertical-align: middle;
  padding: 0.75rem 1rem;
  border: none;
}
.table-wrapper .table tfoot {
  background: white;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid var(--dark5);
}
.pagination label {
  font-size: var(--s);
  color: var(--dark50);
  padding: 0 0.5rem;
}
.pagination select {
  padding: 0.125rem;
  background: var(--dark10);
  border-radius: 4px;
  border-color: var(--dark10);
  font-size: var(--s);
  font-weight: 300;
  margin-right: 1rem;
}
.pagination .btn {
  padding: 0;
  background: transparent;
  color: var(--dark);
  border: none;
  margin: 0 0.5rem;
}
.pagination .btn[disabled] {
  color: var(--dark30);
}

.datepicker-wrapper {
  max-width: 500px;
  margin: 1rem 0;
}
.datepicker-wrapper .react-datepicker {
  width: 100%;
  border: none;
}
.datepicker-wrapper .react-datepicker .react-datepicker__navigation {
  top: 14px;
}
.datepicker-wrapper .react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 12px;
  width: 12px;
  border-color: var(--prim);
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container {
  float: none;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
  background: transparent;
  border: none;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
  border-radius: 20px;
  padding: 0.35rem;
  font-size: var(--l);
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day-name,
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day,
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__time-name {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0.125rem;
  padding: 0;
  border-radius: 50%;
  font-size: var(--ml);
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day-names {
  margin-top: 0.5rem;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day-names .react-datepicker__day-name {
  font-weight: 300;
  color: var(--dark50);
  height: 30px;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day {
  color: var(--dark);
  font-weight: 400;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--today {
  font-weight: 700;
  color: var(--sec);
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--today.react-datepicker__day--selected {
  background: var(--prim);
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--disabled {
  opacity: 0.4;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--selected, .datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: var(--prim);
  color: white;
  border-radius: 50%;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--outside-month {
  visibility: hidden;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-selecting-range {
  background: transparent;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range {
  background: var(--prim10);
  border-radius: 0;
  color: var(--prim);
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--selected {
  background: var(--prim);
  color: white;
  border-radius: 50%;
  position: relative;
  z-index: 3;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--selected + .react-datepicker__day--in-range {
  position: relative;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--selected + .react-datepicker__day--in-range:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  height: 100%;
  width: 50%;
  background: var(--prim10);
  z-index: 0;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--range-start) {
  background: var(--prim10);
  color: white;
  border-radius: 0 50% 50% 0;
  z-index: 1;
  position: relative;
}
.datepicker-wrapper .react-datepicker .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--range-start):after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 50%;
  background: var(--prim);
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  height: var(--header-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: var(--m-shadow); */
  background: var(--prim30);
}
header .dropdown-toggle:after {
  display: none;
}
header > .container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header nav ul {
  padding: 0;
  display: flex;
  align-items: flex-end;
  margin: 0;
}
header nav ul li {
  list-style: none;
  height: calc(100% - 20px);
  margin: 5px 0 0 1rem;
  position: relative;
}
header nav ul li a {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  color: var(--prim);
  font-weight: 600;
  font-size: var(--m);
  border-radius: 16px 16px 0 0;
  position: relative;
  transition: none;
}
header nav ul li a:hover {
  color: var(--sec);
}
header nav ul li a.current {
  background: white;
}
header nav ul li a.current:before {
  content: "";
  width: 60px;
  height: 4px;
  background: var(--orange);
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: auto;
}
header nav ul li a.current + .corners {
  display: block;
}
header nav ul .corners {
  position: absolute;
  display: none;
  background: white;
  height: 16px;
  bottom: 0;
  left: -16px;
  width: calc(100% + 32px);
  overflow: visible;
  pointer-events: none;
}
header nav ul .corners:before {
  content: "";
  border: var(--prim30) solid;
  border-width: 0 1em 1em 0;
  border-radius: 0 0 100% 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  height: 100%;
  clip: rect(0 1em 1em 0);
  background: white;
}
header nav ul .corners:after {
  content: "";
  border: var(--prim30) solid;
  border-width: 0 1em 1em 0;
  border-radius: 0 0 100% 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 100%;
  clip: rect(0 1em 1em 0);
  background: white;
  transform: scaleX(-1);
}

footer {
  background: var(--blue1);
  color: white;
  padding: 2rem 0;
  height: 160px;
}
footer nav ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
footer nav ul li {
  list-style: none;
  flex: 0 0 33%;
  margin-top: 0.5rem;
}
footer nav ul li a {
  color: white;
  font-size: var(--s);
  font-weight: 100;
}
footer nav ul li a:hover {
  text-decoration: underline;
  color: white;
}
footer a.fw-semibold {
  opacity: 1;
}
footer a.fw-semibold:hover {
  opacity: 0.8;
}

.card {
  padding: 1rem;
  border-radius: 20px;
  border: none;
  box-shadow: var(--m-shadow);
}

.square-number {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  background: var(--sec);
  border-radius: 16px;
  font-size: var(--l);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.steps {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  position: relative;
  max-width: calc(100% - 4rem);
  margin: 0 auto;
}
.steps .bar {
  height: 4px;
  width: 100%;
  background: var(--dark30);
}
.steps > div {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.steps > div + .bar {
  background: var(--prim30);
}
.steps > div span.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--prim);
  border: 3px solid white;
}
.steps > div p {
  color: var(--sec);
  width: auto;
  padding-top: 0.5rem;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps > div p i {
  display: none;
}
.steps > div.completed + .bar {
  background: var(--green70);
}
.steps > div.completed span.dot {
  background: var(--green70);
}
.steps > div.completed p {
  color: var(--green70) !important;
}
.steps > div.completed p span {
  text-decoration: underline;
}
.steps > div.completed p i {
  display: inline;
  padding-right: 0.25rem;
}
.steps > div.disabled + .bar {
  background: var(--dark30);
}
.steps > div.disabled span.dot {
  background: var(--dark30);
}
.steps > div.disabled p {
  color: var(--dark30) !important;
}

.accordion .accordion-item {
  border-radius: 20px;
  background: var(--prim5);
  overflow: hidden;
  border: none;
  /* box-shadow: var(--m-shadow); */
}
.accordion .accordion-item .accordion-header .accordion-button {
  background: var(--prim5);
  border: none;
  flex-wrap: wrap;
  padding: 1.5rem;
  box-shadow: none;
}
.accordion .accordion-item .accordion-header .accordion-button h2,
.accordion .accordion-item .accordion-header .accordion-button p {
  width: 100%;
}
.accordion .accordion-item .accordion-header .accordion-button p {
  padding-left: 2rem;
}
.accordion .accordion-item .accordion-header .accordion-button:after {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.accordion .accordion-item .accordion-body {
  padding: 0 1.5rem 1.5rem;
}
.accordion .accordion-item .accordion-body .form-group {
  margin: 0 0 1.5rem;
}
.accordion .fake-accordion-header {
  background: var(--prim5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 20px 20px 0 0;
}
.accordion .fake-accordion-header + .accordion-item {
  border-radius: 0 0 20px 20px;
}
.accordion .fake-accordion-header + .accordion-item .accordion-header .accordion-button {
  background: var(--prim30);
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sec);
}
.accordion .fake-accordion-header + .accordion-item .accordion-header .accordion-button:after {
  position: static;
  margin: 0 0 0 0.5rem;
  color: var(--sec);
}
.accordion .fake-accordion-header + .accordion-item .accordion-header .accordion-button > span {
  display: inline-block;
}
.accordion .fake-accordion-header + .accordion-item .accordion-header .accordion-button > span.collapsed-shown {
  display: none;
}
.accordion .fake-accordion-header + .accordion-item .accordion-header .accordion-button.collapsed > span {
  display: none;
}
.accordion .fake-accordion-header + .accordion-item .accordion-header .accordion-button.collapsed > span.collapsed-shown {
  display: inline-block;
}
.accordion .fake-accordion-header .rounded-circle {
  flex: 0 0 60px;
}

.reminder-card {
  background: var(--prim5);
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.reminder-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.badge {
  padding: 0.45rem 0.75rem 0.35rem;
  border-radius: 24px;
  color: var(--dark);
  background: transparent;
  border: 1px solid var(--dark30);
  font-size: var(--s);
  margin: 0.25rem;
  font-weight: 300;
  display: inline-block;
}
.badge.btn {
  color: var(--dark);
  border: 1px solid var(--dark30);
  background: transparent;
}
.badge.btn.blue {
  color: white;
  background: var(--prim70);
  border-color: var(--prim70);
}
.badge:empty {
  display: inline-block;
}
.badge.green {
  color: white;
  background: var(--green70);
  border-color: var(--green70);
}
.badge.red {
  color: white;
  background: var(--red70);
  border-color: var(--red70);
}
.badge.blue {
  color: white;
  background: var(--prim70);
  border-color: var(--prim70);
}
.badge.light-blue {
  background: var(--prim10);
  border-color: var(--prim10);
  color: var(--prim);
}
.badge.granate {
  color: white;
  background: var(--granate);
  border-color: var(--granate);
}

.note {
  /*   background: var(--yellow50); */
  background: var(--prim5);
  border-radius: 8px;
  /*  border-left: 10px solid var(--yellow); */
  padding: 0.75rem;
  margin: 0 0 1rem;
}

.appointment-list.no-visit-button .appointment a.font-xs {
  display: none !important;
}

.appointment {
  background: var(--prim5);
  border-radius: 8px;
  border-left: 10px solid transparent;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.appointment.empty {
  background: var(--dark10);
  opacity: 0.3;
}
.appointment.outdated {
  background: #F5F5F5;
}
.appointment.orange {
  border-color: var(--orange);
}
.appointment.red, .appointment.riesgo_cardiovascular {
  border-color: var(--red);
}
.appointment.green, .appointment.composicion_corporal {
  border-color: var(--green);
}
.appointment.granate, .appointment.retorno_venoso {
  border-color: var(--granate);
}
.appointment.blue {
  border-color: var(--blue);
}
.appointment.dark-blue, .appointment.mediciones_adicionales {
  border-color: var(--sec);
}
.appointment.dark-pink, .appointment.diabetes {
  border-color: var(--dark-pink);
}
.appointment.pink, .appointment.osteoporosis {
  border-color: var(--pink);
}
.appointment [role=button] {
  position: relative;
  z-index: 3;
}

.medition-card {
  background: var(--prim5);
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}
.medition-card .image-wrapper {
  height: 150px;
  position: relative;
  overflow: hidden;
}
.medition-card .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.gauge-wrapper {
  padding: 0 2rem;
}
.gauge-wrapper .CircularProgressbar {
  width: 90px;
  margin: 0 auto;
  display: block;
  position: relative;
}
.gauge-wrapper .CircularProgressbar .CircularProgressbar-path {
  animation: animation-path 1s ease;
  transition: stroke-dashoffset 1s ease 0s;
}
@keyframes animation-path {
  0% {
    stroke-dashoffset: 289.027px;
  }
  100% {
    stroke-dashoffset: auto;
  }
}
.gauge-wrapper + p {
  font-weight: 600;
  margin: 1rem auto 0;
  text-align: center;
  max-width: 120px;
}

.empty-bar {
  display: block;
  border-radius: 8px;
  width: 100%;
  opacity: 0.5;
  margin: 0 0 0.5rem;
}

.nav-tabs {
  display: flex;
  flex-wrap: nowrap;
}
.nav-tabs .nav-item {
  width: 100%;
  border: none;
}
.nav-tabs .nav-item .nav-link {
  width: 100%;
  text-align: center;
  border: none;
  border-bottom: 3px solid var(--dark10);
  color: var(--dark30);
  font-weight: 300;
}
.nav-tabs .nav-item .nav-link.active {
  border-color: var(--prim);
  color: var(--prim);
  font-weight: 600;
}

.service-chip {
  display: inline-block;
  font-weight: 300;
  color: var(--prim);
  font-size: var(--s);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background: var(--sec5);
  padding: 0.3rem 0.75rem 0.15rem;
  margin: 0 0.125rem 0.25rem;
  white-space: nowrap;
  text-transform: capitalize;
}
.service-chip:before {
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.service-chip.border-left-orange:before {
  background: var(--orange);
}
.service-chip.border-left-blue:before, .service-chip.border-left-piel:before {
  background: var(--blue);
}
.service-chip.border-left-dark-blue:before, .service-chip.border-left-mediciones_adicionales:before, .service-chip.border-left-todos:before {
  background: var(--sec);
}
.service-chip.border-left-red:before, .service-chip.border-left-riesgo_cardiovascular:before {
  background: var(--red);
}
.service-chip.border-left-granate:before, .service-chip.border-left-retorno_venoso:before {
  background: var(--granate);
}
.service-chip.border-left-pink:before, .service-chip.border-left-osteoporosis:before {
  background: var(--pink);
}
.service-chip.border-left-dark-pink:before, .service-chip.border-left-diabetes:before {
  background: var(--dark-pink);
}
.service-chip.border-left-green:before, .service-chip.border-left-composicion_corporal:before {
  background: var(--green);
}

.service-card {
  display: flex;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-shadow: var(--m-shadow);
}
.service-card .image-wrapper {
  flex: 0 0 160px;
  width: 160px;
}
.service-card .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.service-card .border-left {
  border-left: 5px solid transparent;
}
.service-card .card-content {
  width: calc(100% - 160px);
  flex: 0 0 calc(100% - 160px);
}
.service-card .service-chip {
  max-width: 400px;
  text-transform: none;
}
.service-card .service-chip p {
  white-space: normal;
}
.service-card.blue .border-left {
  border-color: var(--blue3);
}
.service-card.red .border-left {
  border-color: var(--red);
}
.service-card.green .border-left, .service-card.composicion_corporal .border-left {
  border-color: var(--green);
}
.service-card.granate .border-left {
  border-color: var(--granate);
}
.service-card.purple .border-left {
  border-color: var(--purple);
}
.service-card.grey .border-left {
  border-color: var(--dark10);
}
.service-card.pink .border-left, .service-card.osteoporosis .border-left {
  border-color: var(--pink);
}
.service-card.dark-pink .border-left, .service-card.diabetes .border-left {
  border-color: var(--dark-pink);
}
.service-card.dark-blue .border-left {
  border-color: var(--sec);
}
.service-card.orange .border-left {
  border-color: var(--orange);
}

.service-type {
  width: 30px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
}
.service-type.blue {
  background: var(--blue3);
}
.service-type.red {
  background: var(--red);
}
.service-type.green, .service-type.composicion_corporal {
  background: var(--green);
}
.service-type.granate {
  background: var(--granate);
}
.service-type.purple {
  background: var(--purple);
}
.service-type.grey {
  background: var(--dark10);
}
.service-type.pink, .service-type.osteoporosis {
  background: var(--pink);
}
.service-type.dark-pink, .service-type.diabetes {
  background: var(--dark-pink);
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pharmacy-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  padding: 2rem;
}
.pharmacy-header .pharmacy-logo {
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
}
.pharmacy-header .border-left {
  border-left: 1px solid var(--dark10);
  padding: 0 2rem;
}

.carousel .carousel-indicators {
  display: none;
}
.carousel .carousel-item > div {
  padding: 0 0 1rem;
  display: flex;
  justify-content: space-around;
}
.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  color: var(--prim70);
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
}
.carousel .carousel-control-prev span,
.carousel .carousel-control-next span {
  display: none;
}
.carousel .carousel-control-prev:before {
  content: "\e92d";
  font-family: "ssp-icons" !important;
  font-size: 24px;
}
.carousel .carousel-control-next:before {
  content: "\e92b";
  font-family: "ssp-icons" !important;
  font-size: 24px;
}

.carousel-btn i {
  font-size: var(--xl);
}
@media (max-width: 1400px) {
  .carousel-btn i {
    font-size: var(--l);
  }
}
@media (max-width: 1400px) {
  .carousel-btn {
    width: 20px;
  }
}

.toast {
  --bs-toast-max-width: 500px;
  position: fixed;
  top: calc(1rem + var(--header-height));
  bottom: unset !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999999 !important;
}
.toast .toast-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toast.bg-success {
  background: var(--green70) !important;
  border-color: var(--green70) !important;
}

.service-row {
  background: var(--prim5);
  border-radius: 16px;
  padding: 1rem;
  display: flex;
  align-items: stretch;
  margin-bottom: 1.5rem;
}
.service-row .medition-input {
  margin-right: 1rem;
}
.service-row > div:first-of-type {
  height: auto;
  flex: 0 0 calc(100% - 180px);
}
.service-row > div:last-of-type {
  height: auto;
}
.service-row > div:last-of-type > div {
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 180px;
}
.service-row > div:last-of-type > div .service-chip {
  width: auto;
  background: var(--prim10);
  text-transform: capitalize;
}
.service-row > div:last-of-type > div .btn {
  white-space: nowrap;
}
.service-row > div:last-of-type > div .medition-img {
  max-width: 180px;
}
.service-row > div:last-of-type > div .medition-img img {
  width: 100%;
}
.service-row .results {
  padding: 2rem 1rem 0 0;
}
.service-row .results h4 {
  margin-bottom: 0.5rem;
  color: var(--green);
  display: flex;
  align-items: center;
}
.service-row .results h4:before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.25rem;
}
.service-row .results p {
  padding-left: calc(14px + 0.25rem);
}
.service-row .results.green h4 {
  color: var(--green70);
}
.service-row .results.green h4:before {
  background: var(--green70);
}
.service-row .results.orange h4 {
  color: var(--orange);
}
.service-row .results.orange h4:before {
  background: var(--orange);
}
.service-row .results.red h4 {
  color: var(--red);
}
.service-row .results.red h4:before {
  background: var(--red);
}

.service-check {
  overflow: hidden;
  margin: 1rem 0;
}
.service-check > p {
  border-left: 8px solid transparent;
}
.service-check.border-left-orange > p {
  border-color: var(--orange);
}
.service-check.border-left-blue > p {
  border-color: var(--blue);
}
.service-check.border-left-dark-blue > p, .service-check.border-left-mediciones_adicionales > p {
  border-color: var(--sec);
}
.service-check.border-left-red > p, .service-check.border-left-riesgo_cardiovascular > p {
  border-color: var(--red);
}
.service-check.border-left-granate > p, .service-check.border-left-retorno_venoso > p {
  border-color: var(--granate);
}
.service-check.border-left-pink > p, .service-check.border-left-osteoporosis > p {
  border-color: var(--pink);
}
.service-check.border-left-dark-pink > p, .service-check.border-left-diabetes > p {
  border-color: var(--dark-pink);
}
.service-check.border-left-green > p, .service-check.border-left-composicion_corporal > p {
  border-color: var(--green);
}

.survey .form-group {
  margin-bottom: 0;
}
.survey .row {
  --bs-gutter-x: .75rem;
}

@font-face {
  font-family: "Volta";
  src: url("~/public/assets/fonts/VoltaModernDisplay-45Light.otf") format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "Volta";
  src: url("~/public/assets/fonts/VoltaModernText-55Roman.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Volta";
  src: url("~/public/assets/fonts/VoltaModernText-65Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Volta";
  src: url("~/public/assets/fonts/VoltaModernText-75Bold.otf") format("opentype");
  font-weight: 700;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* LAYOUT */
body {
  --ion-font-family: "Volta", sans-serif;
  --bs-font-sans-serif:"Volta", sans-serif;
  --bs-body-color: var(--dark);
  font-family: "Volta", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body a {
  text-decoration: none;
  transition: var(--trans);
}
body a.link:hover {
  text-decoration: underline;
}
body ul {
  padding-left: 1rem;
  margin: 0;
}
body hr {
  border-color: var(--dark30);
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p,
body label {
  margin: 0;
  line-height: 1.2;
}
body #root main {
  position: relative;
  width: 100%;
  padding-top: var(--header-height);
}
body #root main .content-wrapper {
  min-height: calc(100vh - 160px);
}
body #root main .content-wrapper > .container {
  padding-top: 2.5rem;
}

/* Overwrite bootstrap */
@media (min-width: 1600px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1584px;
  }
}
/* MAQUETA IMPRESION */
.print-title {
  display: none;
}

.print-show {
  display: none !important;
}

@media print {
  html, body {
    height: 100%; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 15px !important;
    /* overflow: hidden; */
  }
  html .print-title, body .print-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  html .print-title img, body .print-title img {
    height: 25px;
    width: auto;
  }
  html .print-show, body .print-show {
    display: block !important;
  }
  html .print-none, body .print-none {
    display: none !important;
  }
  html .print-nomargin, body .print-nomargin {
    margin-top: 0 !important;
  }
  html .appointment, html .service-row, body .appointment, body .service-row {
    background: transparent !important;
    border-left: none;
    color: var(--dark) !important;
  }
  html .appointment .font-xs, html .service-row .font-xs, body .appointment .font-xs, body .service-row .font-xs {
    font-size: 16px !important;
  }
  html .appointment .font-s, html .service-row .font-s, body .appointment .font-s, body .service-row .font-s {
    font-size: 19px !important;
  }
  html .appointment .font-m, html .service-row .font-m, body .appointment .font-m, body .service-row .font-m {
    font-size: 22px !important;
  }
  html .appointment .font-m.text-decoration-underline, html .service-row .font-m.text-decoration-underline, body .appointment .font-m.text-decoration-underline, body .service-row .font-m.text-decoration-underline {
    font-size: 25px !important;
    color: var(--dark) !important;
  }
  html .appointment .color-dark50, html .appointment .form-group.readonly label, html .service-row .color-dark50, html .service-row .form-group.readonly label, body .appointment .color-dark50, body .appointment .form-group.readonly label, body .service-row .color-dark50, body .service-row .form-group.readonly label {
    color: var(--dark) !important;
  }
  html .appointment .medition-input, html .service-row .medition-input, body .appointment .medition-input, body .service-row .medition-input {
    border-color: var(--dark50) !important;
  }
  html .appointment .medition-input .form-group .form-label, html .service-row .medition-input .form-group .form-label, body .appointment .medition-input .form-group .form-label, body .service-row .medition-input .form-group .form-label {
    color: var(--dark) !important;
    white-space: pre-wrap;
  }
  html .appointment .medition-input .form-group .form-control, html .service-row .medition-input .form-group .form-control, body .appointment .medition-input .form-group .form-control, body .service-row .medition-input .form-group .form-control {
    border-color: var(--dark30) !important;
  }
}/*# sourceMappingURL=styles.css.map */